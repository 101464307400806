import Main from "../../layout/Main";
import styles from './index.module.css';
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Collapse } from 'antd';
const { Panel } = Collapse;

const Requests = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const navigate = useNavigate();

    const loading = false;
    const data = {
        requests: [
            {
                provider: {
                    name: 'WIWO Hospital',
                },
                operator: 'Sumit Pratihar',
            },
            {
                provider: {
                    name: 'Life Care Pharmacy',
                },
                operator: 'Harshad M',
            }
        ]
    }
    // const { data, loading } = useSelector(state => state.requests)

    return (
        <Main pageName="Employers">

        </Main >
    )
}

export default Requests;