import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { baseURL } from '../config'
const initialState = {
    loading: false,
    data: [],
    isLoading: false,
    details: {
        data: null,
        isLoading: false
    },
    property: {
        empty: false,
        first: false,
        last: false,
        page_number: 0,
        page_size: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false
        },
        total_elements: 0,
        total_pages: 0
    }
};

let token = localStorage.getItem('x-auth-token');
export const loadWellness = createAsyncThunk('wellness/loadWellness', async ({ page, pageSize }) => {
    return await fetch(`${baseURL}pas/wellness-partner/profiles?page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});
export const searchWellness = createAsyncThunk('wellness/searchWellness', async ({ query, page, pageSize }) => {
    return await fetch(`${baseURL}pas/wellness-partner/search?query=${query}&page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});
export const getWellnessDetails = createAsyncThunk('wellness/getWellnessDetails', async (wellnessId) => {
    return await fetch(`${baseURL}pas/wellness-partner/${wellnessId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(async res => {
            const branches = await fetch(`${baseURL}pas/wellness-partner/${wellnessId}/location/list`, {
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
                }
            })
                .then(branches => branches.json())
                .then(branches => branches.result)
            return ({ ...res.result, branch: branches })
        })
        .catch(e => console.log(e))
});
export const getWellnessLocations = createAsyncThunk('wellness/getWellnessLocations', async (wellnessId) => {
    return await fetch(`${baseURL}pas/wellness-partner/${wellnessId}/location/list`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});
export const addLocation = createAsyncThunk('wellness/addLocation', async ({ wellnessId, data }) => {
    return await fetch(`${baseURL}pas/wellness-partner/${wellnessId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const WellnessSlice = createSlice({
    name: 'wellness',
    initialState: initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(loadWellness.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadWellness.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(loadWellness.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(searchWellness.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchWellness.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(searchWellness.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getWellnessDetails.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getWellnessDetails.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.data = action.payload;
        });
        builder.addCase(getWellnessDetails.rejected, (state) => {
            state.details.isLoading = false;
        });
        builder.addCase(getWellnessLocations.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getWellnessLocations.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.data.branch = action.payload;
        });
        builder.addCase(getWellnessLocations.rejected, (state) => {
            state.details.isLoading = false;
        });
    },
});

export default WellnessSlice.reducer;
