import React, { useEffect, useState } from 'react';
import { LogoutOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Layout, Button, Table, Select, Form, Input, Modal, Badge } from 'antd';
import { BsBuilding } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../config';
import styles from './index.module.css'
const { Option } = Select;
const HeaderComponenet = () => {
  const { Header: AntHeader } = Layout;

  const [requestsCount, setRequestsCount] = useState(5)

  const navigate = useNavigate();
  const [userModal, setUserModal] = useState(false)

  const handleMenuClick = async (e) => {
    if (e.key === '1') {
      navigate('/providers');
    }
    if (e.key === '3') {
      setUserModal(true)
    }
    if (e.key === '4') {
      localStorage.clear();
      navigate('/login');
    }
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('user'))) {
      localStorage.clear();
      navigate('/login');
    }
  }, [])


  const menu = (
    <Menu
      onClick={handleMenuClick}
      className={styles.menu}
      items={[
        {
          label: 'Providers',
          key: '1',
          icon: <BsBuilding />,
        },
        JSON.parse(localStorage.getItem('user'))?.role === 'Super_Admin' ||
          JSON.parse(localStorage.getItem('user'))?.role === 'Admin' ?
          {
            label: 'Manage Users',
            key: '3',
            icon: <UsergroupAddOutlined />,
          }
          : null,
        {
          label: 'Logout',
          key: '4',
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  const UserModal = () => {
    const [users, setUsers] = useState([])
    const [newUser, setNewUser] = useState(false)
    const [success, setSuccess] = useState(false)
    const [msg, setMsg] = useState('')

    const { Option } = Select;
    useEffect(() => {
      (async () => {
        const response = fetch(`${baseURL}user/profiles/PAS?size=100&page=0`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
          }
        })
          .then(res => res.json())
          .then(res => {
            if (res.api.responseCode == 2040) {
              setUsers(res.result.content)
            }
          })

      })()
      console.log('suucess changed ', users)
    }, [success])

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: 60,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: 90,
      },
      {
        title: 'Phone',
        dataIndex: 'contact_number',
        width: 70,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: 50,
        render: role => role.replaceAll('_', ' ')
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 40,
        render: status => status === 'Active' ?
          <div className={styles.statusContainer}>
            <div className={styles.statusDot}></div>
            <p> Active</p>
          </div> :
          <div className={styles.statusContainer}>
            <div className={styles.statusDot}></div>
            <p> Inactive</p>
          </div>
      },
      {
        title: 'Action',
        dataIndex: '',
        width: 40,
        render: user => <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button disabled={user.status === 'Active'} type="primary" size="small" style={{ borderRadius: '15px' }} onClick={() => activateUser(user.user_id)}>Activate</Button>
        </div>
      },

    ];
    const activateUser = async (userID) => {
      const response = await fetch(`${baseURL}user/${userID}/activate`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
        },
      })
        .then(res => res.json())
        .then(res => {
          if (res.api.responseCode === 2030) {
            setSuccess(true)
          }
        })
    }
    const addNewUser = async (val) => {
      const dataup = {
        contact_number: val.phone,
        email: val.email,
        first_name: val.first_name,
        last_name: val.last_name,
        ref_access: 'PAS',
        role: val.role,
      }
      const response = await fetch(`${baseURL}user`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
        },
        body: JSON.stringify(dataup)
      })
        .then(res => res.json())
        .then(res => {
          if (res.api.responseCode === 2010) {
            setMsg('Successfully added user.')
            setSuccess(true)
          } else {
            setMsg(res.api.response.replaceAll('_', ' '))
            setSuccess(true)
          }
        })
    }
    return (
      <Modal
        centered
        visible={userModal}
        footer={null}
        width={!newUser ? '75%' : '35%'}
        style={{
          minWidth: '500px'
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <h2 style={{ position: 'absolute', top: 5, left: 5 }}>Manage Users</h2>
          <Button

            onClick={() => setUserModal(false)}
            style={{ position: 'absolute', top: 5, right: 5, borderRadius: '15px' }}
          >X</Button>
          {newUser ?

            (
              !success ?
                <Form onFinish={addNewUser} style={{ width: '50%' }}>
                  <div className={styles.addUserFormContainer} >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required.',
                        },
                      ]}
                      name="first_name"
                      className={styles.formFieldHalf}
                    >
                      <div>
                        <div className={styles.formInputContainer}><p>First Name</p> <span style={{ color: 'red' }}>*</span></div>
                        <Input placeholder="Enter First Name" style={{ lineHeight: '25px' }} />
                      </div>
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required.',
                        },
                      ]}
                      name="last_name"
                      className={styles.formFieldHalf}
                    >
                      <div>
                        <div className={styles.formInputContainer}><p>Last Name</p> <span style={{ color: 'red' }}>*</span></div>
                        <Input placeholder="Enter Last Name" style={{ lineHeight: '25px' }} />
                      </div>
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required.',
                          type: 'email'
                        },
                      ]}
                      name="email"
                      className={styles.formFieldHalf}
                    >
                      <div>
                        <div className={styles.formInputContainer}><p>Email</p> <span style={{ color: 'red' }}>*</span></div>
                        <Input type='email' placeholder="Enter Email" style={{ lineHeight: '25px' }} />
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      className={styles.formFieldHalf}
                    >
                      <div>
                        <div className={styles.formInputContainer}><p>Phone</p> <span style={{ color: 'red' }}></span></div>
                        <Input placeholder="Enter Phone Number" type="number" style={{ lineHeight: '25px' }} />
                      </div>
                    </Form.Item>
                    <div className={styles.formFieldHalf} >
                      <div className={styles.formInputContainer}><p>Role</p> <span style={{ color: 'red' }}>*</span></div>
                      <Form.Item
                        name='role'
                        rules={[
                          {
                            required: true,
                            message: 'Please input role!',
                          },
                        ]}
                      >

                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          dropdownStyle={{ borderRadius: '12px' }}
                          placeholder='Select Role'
                        >
                          <Option value="Super_Admin">
                            Super Admin
                          </Option>
                          <Option value="Admin">
                            Admin
                          </Option>
                          <Option value="Operator">
                            Operator
                          </Option>
                          <Option value="PAS_Provider">
                            PAS Provider
                          </Option>
                          <Option value="Provider_Operator">
                            Provider Operator
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', width: '100%' }}>
                      <Button
                        style={{ borderRadius: '15px', width: '150px' }}
                        onClick={() => setNewUser(false)}
                      >Back</Button>
                      <Button
                        style={{ borderRadius: '15px', width: '150px' }}
                        htmlType="submit"
                        type="primary"
                      >Add</Button>
                    </div>
                  </div>
                </Form> :
                <div style={{ width: '100%', gap: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-end', alignItems: 'flex-start', margin: '25px', marginTop: '75px' }}>
                  <h3 style={{ textAlign: 'left', fontSize: '18px', fontWeight: '500', color: '#3a3a3aaa' }}>{msg}</h3>
                  <Button type='primary' style={{ borderRadius: '15px' }}
                    onClick={() => { setNewUser(false); setSuccess(false) }}
                  >Proceed</Button>
                </div>
            )
            :

            <div style={{ padding: '25px', paddingTop: '75px', minWidth: '450px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '25px' }}>
              <Table
                columns={columns}
                dataSource={users}
                scroll={{
                  y: '450px',
                }}
                rowKey="user_id"
                pagination={false}
                style={{
                  borderRadius: '15px', overflow: 'hidden', width: '100%',
                }}
              />
              <Button
                style={{ borderRadius: '15px', width: '150px' }}
                onClick={() => setNewUser(true)}
              >Add New User</Button>
            </div>
          }

        </div>
      </Modal >
    )
  }

  let user = JSON.parse(localStorage.getItem('user'))?.first_name;

  return (
    <div style={{ backgroundColor: 'inherit', marginRight: '75px' }}>
      {userModal ? <UserModal userModal={userModal} setUserModal={setUserModal} /> : null}

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <AntHeader style={{ backgroundColor: 'inherit' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15, position: 'relative', alignItems: 'center' }}>
            <Dropdown
              overlay={menu}
              getPopupContainer={trigger => trigger.parentElement}
              trigger={['click', 'hover']}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 25 }}>
                <img src="/icons/user.png" style={{ width: 30 }} alt="" />
                <p style={{ marginBottom: 0, textTransform: 'capitalize', cursor: 'default' }}>{user}</p>
              </div>
            </Dropdown>
          </div>
        </AntHeader>
      </div >
    </div >
  );
};

export default HeaderComponenet;
