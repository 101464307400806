import { createSlice, createAsyncThunk, compose } from '@reduxjs/toolkit';
import axios from 'axios';

import { baseURL } from '../config'
import { useNavigate } from 'react-router-dom';

const initialState = {
  token: typeof window !== 'undefined' && localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

export const LoginWithEmail = createAsyncThunk('user/Login', async (args) => {
  try {
    const response = await axios.post(`${baseURL}user/login`,
      {
        email: args.email,
        password: args.password,
        ref_access: 'PAS'
      }
    );
    if (response?.data?.api.responseCode === 2250) {
      localStorage.setItem('x-auth-token', response.headers.token);
      localStorage.setItem('user', JSON.stringify(response.data?.result))
      args.navigate('/');
    }
    return response;
  } catch (error) {
    return error.response;
  }
});

export const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logOut: (state, actions) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      localStorage.clear();
    },
  },
  extraReducers(builder) {
    builder.addCase(LoginWithEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(LoginWithEmail.fulfilled, (state, action) => {
      switch (action.payload?.data?.api?.responseCode) {
        case 2250:
          state.isLoading = false;
          state.isAuthenticated = true;
          state.error = null;

          break;
        default:
          state.isLoading = false;
          state.isAuthenticated = true;
          state.error = action.payload?.data?.api?.response
          break;
      }
    });
    builder.addCase(LoginWithEmail.rejected, (state) => {
      console.log('errr')
      state.isLoading = false;
    });
  },
});

export default AuthSlice.reducer;
