/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import styles from './index.module.css';
import Logo from '../icons/newLogo.png';

import dashboard from '../icons/dashboard.png';
import dashboardActive from '../icons/dashboardActive.png';
import provider from '../icons/provider.svg'
import reports from '../icons/reports.png';
import employer from '../icons/employer.png'
import wellness from '../icons/wellness.png'


const SideBar = () => {
  const [open, setOpen] = useState(false);

  const links = (JSON.parse(localStorage.getItem('user'))?.role === 'PAS_Provider' || JSON.parse(localStorage.getItem('user'))?.role === 'Provider_Operator') ?
    [
      {
        name: 'Dashboard',
        path: '/',
        icon: dashboard,
        activeIcon: dashboardActive,
        hidden: false
      },
      {
        name: 'Provider',
        path: '/providers',
        icon: provider,
        activeIcon: provider,
        hidden: false
      },
    ] :
    [
      {
        name: 'Dashboard',
        path: '/',
        icon: dashboard,
        activeIcon: dashboardActive,
        hidden: false
      },
      {
        name: 'Provider',
        path: '/providers',
        icon: provider,
        activeIcon: provider,
        hidden: false
      },
      {
        name: 'Employers',
        path: '/employers',
        icon: employer,
        activeIcon: employer,
      },
      {
        name: 'Merchant Partners',
        path: '/merchant-partners',
        icon: wellness,
        activeIcon: wellness,
      },
      {
        name: 'Reports',
        path: '/reports',
        icon: reports,
        activeIcon: reports,
      },

    ];


  return (
    <div
      onMouseLeave={() => setOpen(false)}
      onMouseEnter={() => setOpen(true)}
      className={open ? styles.sideBarOpen : styles.sideBar}
    >
      <Link to={'/'}>
        <img src={Logo} className={styles.logo} />
      </Link>
      <div className={open ? styles.linksOpen : styles.links}>
        {links.map((item) => (
          <NavLink
            hidden={item.hidden}
            key={item.name}
            className={({ isActive }) => (isActive ? styles.linkItemActive : styles.linkItem)}
            to={item.path}
            style={{ width: '100%' }}
          >
            {open ? (
              <div className={styles.openSidebarContainer}>
                <div className={styles.openSidebarIconContainer}>
                  <img className={styles.sidebarIcon} src={item.icon} alt="" />
                  <img className={styles.sidebarIconActiveOpen} src={item.icon} alt="" />
                </div>
                <p className={styles.sidebarText}>{item.name}</p>
              </div>
            ) : (

              <div className={styles.closedSidebarIconContainer}>
                <img className={styles.sidebarIcon} src={item.icon} alt="" />
                <img className={styles.sidebarIconActive} src={item.icon} alt="" />
              </div>

            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
