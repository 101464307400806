import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../config'
const initialState = {
    loading: false,
    data: [],
    isLoading: false,
    details: {
        data: null,
        isLoading: false
    },
    property: {
        empty: false,
        first: false,
        last: false,
        page_number: 0,
        page_size: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false
        },
        total_elements: 0,
        total_pages: 0
    }
};

let token = localStorage.getItem('x-auth-token');

export const loadEmployers = createAsyncThunk('employers/loadEmployers', async ({ page, pageSize }) => {
    return await fetch(`${baseURL}pas/employer/profiles?page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const searchEmployer = createAsyncThunk('employers/searchEmployer', async ({ query, page, pageSize }) => {
    return await fetch(`${baseURL}pas/employer/search?query=${query}&page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const getEmployerDetails = createAsyncThunk('employers/getEmployerDetails', async (employerId) => {
    return await fetch(`${baseURL}pas/employer/${employerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});



export const EmployerSlice = createSlice({
    name: 'employers',
    initialState: initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(loadEmployers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadEmployers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(loadEmployers.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(searchEmployer.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchEmployer.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(searchEmployer.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getEmployerDetails.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getEmployerDetails.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.data = action.payload;
        });
        builder.addCase(getEmployerDetails.rejected, (state) => {
            state.details.isLoading = false;
        });
    },
});

export default EmployerSlice.reducer;
