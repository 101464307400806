import { configureStore } from '@reduxjs/toolkit';

import auth from './authSlice';
import providers from './providerSlice';
import employers from './employerSlice';
import wellness from './wellnessSlice';

export const store = configureStore({
  reducer: {
    auth,
    providers,
    employers,
    wellness,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
