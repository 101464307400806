import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoaderComponenet from '../components/Loader';
import Requests from '../pages/Requests';
import { PrivateRoute } from './PrivateRoute';

const Providers = lazy(() => import('../pages/Providers'));
const OnboardProvider = lazy(() => import('../pages/Providers/Onboard'));
const AddProviderBranch = lazy(() => import('../pages/Providers/AddBranch'));
const ProviderDetail = lazy(() => import('../pages/Providers/ProviderDetail'));
const EmailStatus = lazy(() => import('../pages/Providers/EmailStatus'))

// const EditBranch = lazy(() => import('../pages/EditBranch'));

const Wellness = lazy(() => import('../pages/Wellness'));
const OnboardWellness = lazy(() => import('../pages/Wellness/Onboard'));
const AddWellnessLocation = lazy(() => import('../pages/Wellness/AddLocation'));
const WellnessDetail = lazy(() => import('../pages/Wellness/WellnessDetail'));

const Employers = lazy(() => import('../pages/Employers'));
const OnboardEmployer = lazy(() => import('../pages/Employers/Onboard'));
const EmployerDetail = lazy(() => import('../pages/Employers/Details'));
// const AddProviderBranch = lazy(() => import('../pages/Providers/AddBranch'));
// const ProviderEdit = lazy(() => import('../pages/Providers/ProviderEdit'));


const Dashboard = lazy(() => import('../pages/Dashboard'));

const Login = lazy(() => import('../pages/Login'));
const Welcome = lazy(() => import('../pages/Welcome'));
const ResetPasswordRequest = lazy(() => import('../pages/ResetPasswordReq'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const NotFound = lazy(() => import('../pages/404'));
const Reports = lazy(() => import('../pages/Reports'))

const RouteSetup = () => {
  return (
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers"
          element={
            <PrivateRoute>
              <Providers />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/onboard"
          element={
            <PrivateRoute>
              <OnboardProvider />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:providerId"
          element={
            <PrivateRoute>
              <ProviderDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/email-status"
          element={
            <PrivateRoute>
              <EmailStatus />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:providerId/add-branch"
          element={
            <PrivateRoute>
              <AddProviderBranch />
            </PrivateRoute>
          }
        />
        {/* <Route
            path="/providers/:providerId/edit"
            element={
              <PrivateRoute>
                <ProviderEdit />
              </PrivateRoute>
            }
          /> */}
        <Route
          path="/employers"
          element={
            <PrivateRoute>
              <Employers />
            </PrivateRoute>
          }
        />
        <Route
          path="/employers/onboard"
          element={
            <PrivateRoute>
              <OnboardEmployer />
            </PrivateRoute>
          }
        />
        <Route
          path="/employers/:employerId"
          element={
            <PrivateRoute>
              <EmployerDetail />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/employers/:employerId/add-branch"
          element={
            <PrivateRoute>
              <AddProviderBranch />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/merchant-partners"
          element={
            <PrivateRoute>
              <Wellness />
            </PrivateRoute>
          }
        />
        <Route
          path="/merchant-partners/onboard"
          element={
            <PrivateRoute>
              <OnboardWellness />
            </PrivateRoute>
          }
        />
        <Route
          path="/merchant-partners/:wellnessId"
          element={
            <PrivateRoute>
              <WellnessDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/merchant-partners/:wellnessId/add-location"
          element={
            <PrivateRoute>
              <AddWellnessLocation />
            </PrivateRoute>
          }
        />

        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />

        <Route
          path="/requests"
          element={
            <PrivateRoute>
              <Requests />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/welcome/" element={<Welcome />} />
        <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
