import RouteSetup from './router/RouteSetup';

function App() {
  return (
    <div>
      <RouteSetup />
    </div>
  );
}

export default App;
