import Header from './Header';
import SideBar from './SideBar.js';
import styles from './index.module.css';

function Main({ children, pageName }) {
  return (
    <div className={styles.mainContainer} >
      <SideBar />
      <div className={styles.contain} >
        <Header pageName={pageName} />
        <div >{children}</div>
      </div>
    </div>
  );
}

export default Main;
