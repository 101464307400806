import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../config'
const initialState = {
    loading: false,
    data: [],
    isLoading: false,
    details: {
        data: null,
        isLoading: false
    },
    property: {
        empty: false,
        first: false,
        last: false,
        page_number: 0,
        page_size: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false
        },
        total_elements: 0,
        total_pages: 0
    }
};

let token = localStorage.getItem('x-auth-token');
export const loadProviders = createAsyncThunk('providers/loadProviders', async ({ page, pageSize, status, navigate, sort }) => {
    return await fetch(`${baseURL}pas/provider/profiles?page=${page}&size=${pageSize}${(status && status !== 'All') ? '&status=' + status : ''}${sort ? '&sort=createdTimestamp,' + sort : ''}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => {
            if (res.api.responseCode === 4150) {
                localStorage.clear();
                // navigate('/login');
                window.location.reload();
            }
            return (res.result)
        })
        .catch(e => console.log(e))
});

export const searchProviders = createAsyncThunk('providers/searchProviders', async ({ query, page, pageSize }) => {
    return await fetch(`${baseURL}pas/provider/search?query=${query}&page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const getProviderDetails = createAsyncThunk('providers/getProviderDetails', async (providerId) => {
    return await fetch(`${baseURL}pas/provider/${providerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const addBranch = createAsyncThunk('providers/addBranch', async ({ providerId, data }) => {
    return await fetch(`${baseURL}pas/provider/${providerId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const ProviderSlice = createSlice({
    name: 'providers',
    initialState: initialState,
    reducers: {
        // loadProviders: (state, action) => {
        //     state.data = action.payload;
        // },
    },
    extraReducers(builder) {
        builder.addCase(loadProviders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadProviders.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(loadProviders.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(searchProviders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchProviders.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(searchProviders.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getProviderDetails.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getProviderDetails.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.data = action.payload;
        });
        builder.addCase(getProviderDetails.rejected, (state) => {
            state.details.isLoading = false;
        });
    },
});

export default ProviderSlice.reducer;
